.group {
    position: relative;
    justify-content: center;
    display: flex;
}

.form-input {
    background: none;
    background-color: white;
    color: grey;
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-radius: 0;
    border-bottom: 1px solid grey;
    margin: 25px 0;
}

.form-input:focus {
    outline: none;
}

input[type='color'] {
    padding: 0;
}

.form-input-label {
    color: gray;
    font-size: 16px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    transition: 300ms ease all;
}

.error-out {
    border: 1px solid red;
}

.error {
    text-align: center;
    color: red;
    margin-top: 0;
}