.bottom-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.radio-container {
  padding-bottom: 20px;
}

.error {
  text-align: center;
  color: red;
}